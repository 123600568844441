import React from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title:'3D Animation',
      subtitle:'See Details',
      // href:'/portfolio/portfolio-details',
      src:'/images/Galery/Animation_Boxito.gif'
    },
    {
      title:'Sales simulator',
      subtitle:'See Details',
      // href:'/portfolio/portfolio-details',
      src:'/images/Galery/Horizontal Sales Simulator_La Porta.gif'
    },
    {
      title:'Sales simulator',
      subtitle:'See Details',
      // href:'/portfolio/portfolio-details',
      src:'/images/Galery/atiko.png'
    },
    {
      title:'VR360',
      subtitle:'See Details',
      // href:'/portfolio/portfolio-details',
      src:'/images/Galery/VR360_Unabay.gif'
    },
    {
      title:'Web Design',
      subtitle:'See Details',
      // href:'/portfolio/portfolio-details',
      src:'/images/Galery/Web Design_Torre Capita.png'
    },
  ]
  
  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  
  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {portfolioData.map((item, index)=> (
        <Div key={index}>
          <Portfolio 
            title={item.title} 
            subtitle={item.subtitle}
            href={item.href}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  )
}
