import { Icon } from "@iconify/react";
import React, { useEffect, useRef } from "react";
import { useFormik } from "formik";
import axios from "axios";
import swal from "sweetalert";
import * as yup from "yup";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ReCAPTCHA from "react-google-recaptcha";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import { pageTitle } from "../../helper";

const initialValues = {
  fullName: "",
  email: "",
  projectType: "",
  mobile: "",
  message: "",
  recaptcha: "",
};

export default function ContactPage() {
  pageTitle("Let's Work");

  const recaptchaRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { touched, errors, handleSubmit, handleChange, values, isSubmitting, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: yup.object({
        recaptcha: yup.string().required("Llenar recaptcha"),
        fullName: yup.string().required("El nombre es requerido"),
        email: yup
          .string()
          .email("Correo Inválido")
          .required("El correo es requerido"),
        projectType: yup
          .string()
          .min(2, "Mínimo 2 caracteres")
          .max(100, "Máximo 100 caracteres")
          .required("Campo requerido"),
        mobile: yup
          .string()
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "El numero de teléfono no es válido"
          )
          .min(10, "Mínimo 10 dígitos")
          .max(10, "Máximo 10 dígitos")
          .required("El teléfono es requerido"),
        message: yup
          .string(10, "Se esperan al menos 10 dígitos")
          .max(100, "Se esperan al máximo 100 dígitos")
          .required("Escriba un mensaje"),
      }),
      onSubmit: async (payload, actions) => {
        const res = await axios.post(
          process.env.REACT_APP_FORM_SCRIPT,
          payload,
        );

        if (res.data.success) {
          swal("Enviado", "El correo se ha enviado correctamente", "success");
          actions.resetForm();
          recaptchaRef?.current?.reset();
        } else {
          if (res.data.error) {
            swal("Error", "Ocurrió un problema al enviar el correo", "error");
          }
        }
      },
    });
    const onRecaptchaChange = (recaptchaValue) => {
      setFieldValue("recaptcha", recaptchaValue);
    }
  return (
    <>
      <PageHeading
        title="Let's Work"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form noValidate className="row" onSubmit={handleSubmit}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input
                  name="fullName"
                  type="text"
                  className="cs-form_field"
                  onChange={handleChange}
                  value={values.fullName}
                  required
                />
                {errors.fullName && touched.fullName ? (
                  <div className="error">{errors.fullName}</div>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  name="email"
                  type="text"
                  className="cs-form_field"
                  onChange={handleChange}
                  value={values.email}
                  required
                />
                {errors.email && touched.email ? (
                  <div className="error">{errors.email}</div>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input
                  name="projectType"
                  type="text"
                  className="cs-form_field"
                  onChange={handleChange}
                  value={values.projectType}
                  required
                />
                {errors.projectType && touched.projectType ? (
                  <div className="error">{errors.projectType}</div>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input
                  name="mobile"
                  type="text"
                  className="cs-form_field"
                  onChange={handleChange}
                  value={values.mobile}
                  required
                />
                {errors.mobile && touched.mobile ? (
                  <div className="error">{errors.mobile}</div>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  name="message"
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  onChange={handleChange}
                  value={values.message}
                  required
                ></textarea>
                {errors.message && touched.message ? (
                  <div className="error">{errors.message}</div>
                ) : null}
                <Spacing lg="25" md="25" />
              </Div>
              {/* TODO: https://renderinc.atlassian.net/browse/RT-49 Add recaptcha validation */}
              <div className="row">
                <div className="recaptcha recaptchaes">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaChange} ref={recaptchaRef}/>
                  {errors.recaptcha && touched.recaptcha ? (
                  <div className="error">{errors.recaptcha}</div>
                ) : null}
                </div>
                <Spacing lg="25" md="25" />
            </div>
              <Div className="col-sm-12">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="cs-btn cs-style1"
                >
                  <span>
                    {!isSubmitting ? "Send Message" : "Sending Message"}
                  </span>
                  {!isSubmitting && <Icon icon="bi:arrow-right" />}
                  {isSubmitting && <Icon icon="eos-icons:loading" />}
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Spacing lg="50" md="40" />
    </>
  );
}
