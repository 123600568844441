import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/Logos/altozano.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-arpada-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-citalis-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-mexico-plaza-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-costaverde-cnergy.svg', 
      alt:'Partner'
    },
  ]
  const partnerLogos2 = [
    {
      src: '/images/Logos/svg-condocasa-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-colliers-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-fraterna-cnergy.svg', 
      alt:'Partner'
    },
    {
      className:"logo-mexico",
      src: '/images/Logos/svg-paradigm-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-popinvestments-cnergy.svg', 
      alt:'Partner'
    },
  ]
  const partnerLogos3 = [
    {
      src: '/images/Logos/svg-starmedica-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-slo-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-ruba-cnergy.svg', 
      alt:'Partner'
    },
    {
      src: '/images/Logos/svg-Wöllman-cnergy.svg', 
      alt:'Partner'
    },
  ]
  return (
    <>
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
    </Div>
        <Div className="cs-partner_logo_wrap">
        {partnerLogos2.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
      </Div>
      <Div className="cs-partner_logo_wrap">
        {partnerLogos3.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
      </Div>
      </>
  )
}
