export const Preview360 = () => {
  return (
    <iframe
      src={process.env.REACT_APP_PREVIEW_360_URL}
      style={{
        height: "100vh",
        width: "100vw",
      }}
      frameborder="0"
      allow="gyroscope; accelerometer; xr"
      allowFullScreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    />
  );
};
