import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {

  
  const serviceMenu = [
    {
      title: '3D renderings',
    },
    {
      title: 'Web development',
    },
    {
      title: '3D Film',
    },
    {
      title: 'Interactive',
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div>
              <div className='container-redes'>
              <div className='redes-sociales'>
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/Logos/svg-renderin-don-porfirio-cnergy.svg' 
                  logoAlt='Logo'
                />
                <SocialWidget/>
              </Div>
              </div>
              <div className='info'>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us'/>
              </Div>
            </Div>
            <div className='logo-container'>
            <img className='logo-don-renderinc' src="/images/Logos/svg.renderinc-don-porfirio_cnergy.svg"/>
            </div>
            {/* <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter 
                  title='Subscribe' 
                  subtitle='Follow us.' 
                  placeholder='example@gmail.com'
                />
              </Div>
            </Div> */}
            </div>
            </div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2023 DON PORFIRIO + RENDERINC.</Div>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
