import React, { useEffect } from "react";
import Card from "../Card";
import FunFact from "../FunFact";
import Hero from "../Hero";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";
import LogoList from "../LogoList";
import MovingText from "../MovingText";
import PortfolioSlider from "../Slider/PortfolioSlider";
import PostSlider from "../Slider/PostSlider";
import TestimonialSlider from "../Slider/TestimonialSlider";
import TeamSlider from "../Slider/TeamSlider";
import VideoModal from "../VideoModal";
import TimelineSlider from "../Slider/TimelineSlider";
import { pageTitle } from "../../helper";
// import { useTranslation } from "react-i18next";

export default function Home() {
  // pageTitle(
  //   "CNergy | Unlocking possibilities for Real Estate Marketing",
  //   false
  // );
  // const { t } = useTranslation();

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: "Don Porfirio",
      links: "https://www.donporfirio.tv/",
    },
    {
      name: "Renderinc",
      links: "https://renderinc.mx/",
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: "Global Happy Clients",
      factNumber: "62",
    },
    {
      title: "Project Completed",
      factNumber: "500",
    },
    {
      title: "Team Members",
      factNumber: "49",
    },
    {
      title: "Offices",
      factNumber: "3",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title=" <p>Real Estate</p>Marketing"
        btnText="Let's work"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="POWERED BY"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
        
      />
      {/* End Hero Section */}
      {/* Start Video Block Section */}
      <div id="demo">
        <Div className="container-video">
          {/* <Spacing lg="70" md="70" /> */}
          <VideoModal
            videoSrc="https://www.youtube.com/watch?v=K2UpC0tMtro"
            bgUrl="/images/video_bg.jpeg"
          />
        </Div>
      </div>
      {/* End Video Block Section */}
      {/* Start FunFact Section */}
      <Spacing lg="200" md="180" />
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle="We're excited to share some cool facts about CNergy."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <div id="services">
        <Spacing lg="150" md="80" />
        <Div>
          <Div className="container">
            <Div className="row">
              <Div className="col-xl-4">
                <SectionHeading
                  title="Our Services Power Up Your Project"
                  subtitle="What Can We Do"
                  btnText="Let's work"
                  btnLink="/contact"
                />
                <Spacing lg="90" md="45" />
              </Div>
              <Div className="col-xl-8">
                <Div className="row container-services">
                  <Div className="col-lg-3 col-sm-6 margen">
                    <Card
                      title="3D VISUALIZATION"
                      // link="/service/service-details"
                      src="/images/Services/3d-visualizatio-services-img-cnergy.jpg"
                      alt="Service"
                    />
                  </Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="3D FILM & ANIMATION"
                      // link="/service/service-details"
                      src="/images/Services/3d-film-animation-services-img-cnergy.jpg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="VIRTUAL REALITY"
                      // link="/service/service-details"
                      src="/images/Services/virtual-reality.jpg"
                      alt="Service"
                    />
                  </Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="REAL ESTATE BRANDING"
                      // link="/service/service-details"
                      src="/images/Services/real-estate-branding-services-img-cnergy.jpg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="WEB DEVELOPMENT"
                      // link="/service/service-details"
                      src="/images/Services/web.jpg"
                      alt="Service"
                    />
                  </Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="METAVERSE"
                      // link="/service/service-details"
                      src="/images/Services/metaverse.jpg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div id="gallery">
        <Div className="container">
          <SectionHeading
            title="Portfolio to explore"
            subtitle="Explore Our last work"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>
      {/* End Portfolio Section */}

      {/* Start MovingText Section */}
      <div id="clients">
        <Spacing lg="125" md="70" />

        <MovingText text="Our reputed world wide partners" />
        <Spacing lg="105" md="70" />
      </div>
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s make <br />something <i>cool</i> together"
          btnText="Let's work"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
