import React from 'react';
import parse from 'html-react-parser';
import './hero.scss';
import Button from '../Button';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';

export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <img className='logo-don-renderinc' src="/images/Logos/svg.renderinc-don-porfirio_cnergy.svg"/>
          <Div className="cs-hero_info">
            <Div>
              <Button btnLink={btnLink} btnText={btnText} />
            </Div>    
            <Div>
              <Div className="cs-hero_subtitle">We are experts in crafting <strong><span className='color-naranja'>Premium marketing tools </span></strong>for Real Estate developments.</Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <div className="icono-whatsApp">
              <div className='secundario-whats'>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=+524431926495&text=Me%20gustar%C3%ADa%20llevar%20mi%20proyecto%20al%20siguiente%20nivel%20con%20CNergy!%20%C2%BFMe%20pueden%20dar%20mas%20informaci%C3%B3n?">
          <div className='redondo-whatsApp'>
        <img src="/images/icons/ic-whatsapp.svg"/>
        </div>
        </a>
        </div>
      </div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
