import React from 'react'
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a target="_blank" href='https://www.instagram.com/renderinc.mx/' className='cs-center'>
        <img src="/images/Logos/svg-intagram-color-cnergy.svg"/>
        </a>
        <a target="_blank" href='https://www.facebook.com/renderinc.mx' className='cs-center'>
        <img className='facebook' src="/images/Logos/svg-facebook-color-cnergy.svg"/>
        </a>
        <a target="_blank" href='https://api.whatsapp.com/send?phone=+524431926495&text=Me%20gustar%C3%ADa%20llevar%20mi%20proyecto%20al%20siguiente%20nivel%20con%20CNergy!%20%C2%BFMe%20pueden%20dar%20mas%20informaci%C3%B3n?' className='cs-center'>
        <img className='whats' src="/images/icons/whats.png"/>
        </a>
        <a target="_blank" href='https://www.instagram.com/donporfirio_tv/' className='cs-center-blanco'>
        <img className='ig blanco' src="/images/Logos/svg-intagram-blanco-cnergy.svg"/>
        </a>
        <a target="_blank" href='https://www.facebook.com/DonPorfirioTV/' className='cs-center-blanco'>
        <img className='facebook blanco2' src="/images/Logos/svg-facebook-blanco-cnergy.svg"/>
        </a>

    </Div>
  )
}
